@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.timer {
  display: inline-flex;
  align-items: center;
  @include font-body-14;

  &.large {
    flex-direction: column;
    align-items: flex-start;

    .timerContainer {
      width: 100%;
      justify-content: center;
      border-top: 1px solid $color-dark-blue-10;
      border-bottom: 1px solid $color-dark-blue-10;
      padding: 8px 0 12px 0;
      margin: 8px 0;
    }

    .status {
      margin-left: 8px;
    }

    .unit {
      @include font-body-17;
    }

    .timedOutNumber,
    .timerNumber {
      @include font-heading-10;
      padding: 4px 0;
    }

    .timerWrap {
      min-width: 40px;
      flex-direction: column;
      gap: 0px;
      margin: 0;
      align-items: center;
    }

    .timerColon {
      @include font-heading-10;
      margin: 0 4px;
    }

    @media (min-width: $md) {
      align-items: flex-end;

      .timedOutNumber,
      .timerNumber {
        @include font-heading-7;
      }

      .unit {
        @include font-tagline-12;
      }

      .timerColon {
        @include font-heading-7;
        margin: 0 8px;
      }
    }
  }
}

.status {
  color: $color-dark-blue-100;
}

.timerNumber {
  color: $color-dark-blue-400;
}

.timerColon {
  margin: 0 4px;
  color: $color-dark-blue-50;
}

.unit {
  color: $color-dark-blue-50;
}

.timerContainer {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.timerWrap {
  display: flex;
  gap: 4px;
}

.timedOutNumber {
  color: $color-dark-blue-50;
}
