@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.backBtn {
  color: $color-dark-blue-50;
  text-decoration: none !important;
  @include font-body-15;

  img {
    margin: 0 4px;
  }

  @media (min-width: $md) {
    @include font-body-9;

    img {
      margin: 0 12px;
      width: 12px;
      height: 12px;
    }
  }
}

.proposalDetailsSubheader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;
}

.scrollX {
  overflow-x: auto;
}

.proposalDetailsHeader {
  margin-bottom: 64px;

  @media (min-width: $md) {
    display: flex;
    justify-content: space-between;
  }
}

.proposalDetailsTitle {
  @include font-heading-7;
  margin-bottom: 12px;

  @media (min-width: $sm) {
    @include font-heading-6;
    margin-top: 24px;
  }

  @media (min-width: $md) {
    @include font-heading-4;
    margin-right: 24px;
  }
}

.proposalTag {
  margin-bottom: 32px;

  @media (min-width: $md) {
    margin-bottom: 0;
  }
}

.proposalDetailsTimer {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    align-items: flex-end;
  }
}

.proposalDetailsVoteSection {
  text-align: center;
  margin: 24px 32px;

  button {
    width: 110px;
  }

  @media (min-width: $sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: $md) {
    button {
      width: 150px;
    }
  }
}

.summary {
  margin: 80px 0 40px 0;

  h5 {
    @include font-heading-9;
    margin-bottom: 32px;

    @media (min-width: $sm) {
      @include font-heading-8;
    }

    @media (min-width: $md) {
      @include font-heading-7;
    }
  }
}

.proposalDetailsItem {
  overflow-wrap: break-word;
  @include font-body-9;

  @media (min-width: $md) {
    @include font-body-6;
  }
}

.label {
  @include font-body-7;
  color: $color-dark-blue-50;
  margin-top: 24px;

  @media (min-width: $md) {
    margin-top: 32px;
  }
}

.voteButtonHelperText {
  color: $color-action-error-800;
  margin-top: 16px;
}

.link {
  color: $color-dark-blue-500;
}

.voteButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 24px; //offset for the help icon on the right

  @media (min-width: $md) {
    padding-left: 28px;
  }
}

.helpIcon {
  margin-left: 8px;
  width: 16px;
  height: 16px;

  @media (min-width: $md) {
    width: 20px;
    height: 20px;
  }
}

.malicious {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  background: $color-action-error-10;
  padding: 16px;
  border-top: 1px solid $color-action-error-600;
  border-bottom: 1px solid $color-action-error-600;
  margin: 72px 0;

  .warningText {
    display: flex;
    flex-direction: column;
  }

  .warningTitle {
    @include font-body-10;
  }

  .warningSubtitle {
    @include font-body-12;
  }

  svg {
    color: $color-action-error-600;
    width: 18px;
    height: 18px;
  }

  @media (min-width: $md) {
    padding: 24px 32px;
    margin: 64px 0;
    justify-content: center;

    .warningText {
      flex-direction: row;
      gap: 6px;
    }

    .warningTitle {
      @include font-body-7;
    }

    .warningSubtitle {
      @include font-body-9;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
