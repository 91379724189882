@import '../../../../styles/variables.module.scss';
@import '../../../../styles/fonts.module.scss';

.proposalStatus {
  text-align: center;
}

.failing {
  color: $color-action-error-800;
}

.passing {
  color: $color-action-success-700;
}

.rejected {
  color: $color-action-error-800;
}

.executed {
  color: $color-action-success-700;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: $space-xs;

  svg {
    margin-top: 0;
  }
}

.execute {
  padding: 0;
  font-size: 18px;
  line-height: 28px;
}

.flex {
  display: flex;
  align-items: center;
}

.large {
  @include font-subtitle-8;

  &.flex {
    justify-content: center;
  }

  .icon svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: $md) {
    @include font-subtitle-2;

    .icon svg {
      width: 27px;
      height: 27px;
    }
  }
}
