@import '../../../styles/fonts.module.scss';

@mixin menu-link-secondary {
  position: relative;
  border: none;
  color: $color-dark-blue-400;
  height: 20px;
  padding: 0;

  &.xs {
    @include font-overline-2;
  }

  &.sm {
    @include font-body-15;
  }

  &.md {
    @include font-body-12;
  }

  &.lg {
    height: 24px;
    @include font-description-6;
  }

  &:hover {
    color: $color-blue-400;
  }

  &:active {
    color: $color-blue-300;
  }

  &:disabled {
    color: $color-dark-blue-25;
  }
}
