@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.checkbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &,
  * {
    cursor: pointer;
    transition: all 0.1s;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 16px;
    min-width: 16px;
    height: 16px;
    width: 16px;
    background-color: $color-gray-50;
    border: 1px solid $color-dark-blue-50;
    border-radius: 2px;
    margin-top: 2px;

    svg {
      margin: auto;
      width: 12px;
      height: 12px;
      color: $color-gray-50;
    }
  }

  .checkboxTextBlock {
    display: flex;
    flex-direction: column;
    @include font-body-12;

    label {
      color: $color-dark-blue-800;
    }

    .description {
      color: $color-gray-500;
    }
  }

  &[aria-checked='true'] {
    .checkmark {
      background-color: $color-dark-blue-400;
      border-color: $color-gray-50;
    }
  }

  &:hover:not([aria-disabled='true']) {
    .checkmark {
      background-color: $color-base-light;
      border-color: $color-dark-blue-400;

      svg {
        color: $color-dark-blue-400;
      }
    }

    .checkboxTextBlock {
      label {
        color: $color-gray-900;
      }

      .description {
        color: $color-gray-600;
      }
    }
  }

  &[aria-disabled='true'] {
    pointer-events: none;

    .checkmark {
      background-color: transparent;
      border-color: $color-gray-200;

      svg {
        color: $color-gray-200;
      }
    }

    .checkboxTextBlock {
      label {
        color: $color-gray-400;
      }

      .description {
        color: $color-gray-200;
      }
    }
  }
}

@media (min-width: $sm) {
  .checkbox {
    gap: 12px;

    .checkmark {
      min-height: 20px;
      min-width: 20px;
      height: 20px;
      width: 20px;
      margin-top: 2px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .checkboxTextBlock {
      @include font-body-9;
    }
  }
}
