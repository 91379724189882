@mixin button-tertiary-color {
  position: relative;
  color: $color-blue-500;
  border: 1px solid $color-blue-500;
  background: $color-base-light;

  &.xxs {
    padding: 0 10px;
    border-radius: 24px;
    height: 24px;
    @include font-tagline-10;
  }

  &.xs {
    padding: 0 16px;
    border-radius: 24px;
    height: 32px;
    @include font-tagline-10;
  }

  &.sm {
    padding: 0 20px;
    border-radius: 24px;
    height: 40px;
    @include font-tagline-10;
  }

  &.md {
    padding: 0 24px;
    border-radius: 28px;
    height: 48px;
    @include font-tagline-7;
  }

  &.lg {
    padding: 0 32px;
    border-radius: 32px;
    height: 56px;
    @include font-tagline-7;
  }

  &:hover {
    color: $color-blue-200;
    border: 1px solid $color-blue-200;
  }

  &:active {
    color: $color-blue-600;
    border: 1px solid $color-blue-600;
  }

  &:disabled {
    color: $color-blue-25;
    border: 1px solid $color-blue-25;
  }

  &.dark {
    color: $color-blue-400;
    border: $color-blue-400;

    &:hover {
      color: $color-blue-200;
      border: $color-blue-200;
    }

    &:active {
      color: $color-blue-500;
      border: $color-blue-500;
    }

    &:disabled {
      color: $color-blue-100;
      border: $color-blue-100;
    }
  }
}
