@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';
@import './variants/primary.module.scss';
@import './variants/secondary.module.scss';
@import './variants/secondary-neutral.module.scss';
@import './variants/tertiary-color.module.scss';
@import './variants/link-blue.module.scss';
@import './variants/link-gray.module.scss';
@import './variants/menu-link-secondary.module.scss';
@import './variants/text-blue.module.scss';
@import './variants/text-gray.module.scss';

.button {
  background-color: transparent;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  height: fit-content;

  &.disabled {
    pointer-events: none;
  }

  &.primary {
    @include button-primary;
  }

  &.secondary {
    @include button-secondary;
  }

  &.secondary-neutral {
    @include button-secondary-neutral;
  }

  &.tertiary-color {
    @include button-tertiary-color;
  }

  &.link-blue {
    @include link-blue;
  }

  &.link-gray {
    @include link-gray;
  }

  &.menu-link-secondary {
    @include menu-link-secondary;
  }

  &.text-blue {
    @include text-blue;
  }

  &.text-gray {
    @include text-gray;
  }
}
