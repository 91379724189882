@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

h5 {
  @include font-heading-9;
  color: $color-dark-blue-100;

  @media (min-width: $sm) {
    @include font-heading-8;
  }

  @media (min-width: $md) {
    @include font-heading-7;
  }
}

.header {
  margin-bottom: 32px;

  @media (min-width: $md) {
    margin-bottom: 64px;
  }
}

.proposalsHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: $sm) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.radioButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  svg {
    width: 16px;
    height: 16px;
  }

  label {
    @include font-body-12;
  }

  @media (min-width: $md) {
    svg {
      width: 20px;
      height: 20px;
    }

    label {
      @include font-body-9;
    }
  }
}
