@import '../../styles/variables.module.scss';

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;

  a {
    height: 24px;

    .api3DaoLogo {
      height: 24px;
      width: 103px;
    }
  }

  @media (min-width: $md) {
    padding: 0 24px 0 40px;
    height: 120px;

    a {
      height: 34px;

      .api3DaoLogo {
        height: 34px;
        width: 160px;
      }
    }
  }
}

.right {
  display: flex;
  gap: 16px;
  align-items: center;
}
