@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.pendingUnstakePanel {
  padding-top: 16px;
  border-top: 1px solid $color-dark-blue-10;
  box-sizing: border-box;

  @media (min-width: $md) {
    padding: 24px 24px 8px 24px;
  }
}

.pendingUnstakeContent {
  display: flex;
  flex-direction: column;
}

.pendingUnstakeRow {
  width: 100%;
  display: flex;
  margin-top: $space-xs;

  &.amount {
    align-items: center;
  }

  h5 {
    @include font-subtitle-6;
    color: $color-dark-blue-400;
  }

  @media (min-width: $md) {
    h5 {
      @include font-subtitle-3;
    }
  }
}

.pendingUnstakeTitle {
  @include font-body-11;
  color: $color-dark-blue-50;
  text-align: center;
  padding-top: $space-xs;
}

.pendingUnstakeName {
  @include font-body-11;
  color: $color-dark-blue-100;
  text-align: right;
  margin-right: 24px;
  width: 40%;
}

.pendingUnstakeActions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $space-md;
  margin-top: $space-lg;
  white-space: nowrap;

  * {
    width: 100%;
    max-width: 240px;

    @media (min-width: $md) {
      max-width: 264px;
    }
  }
}

.pendingUnstakeCountdown {
  display: flex;
  margin-left: 2px;

  @media (min-width: $md) {
    margin-left: 4px;
  }
}

.pendingUnstakeCountdownItem {
  @include font-body-11;
  color: $color-dark-blue-400;
  text-align: center;

  & > * {
    margin-bottom: $space-xxs;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

.shortcut {
  @include font-body-14;
  color: $color-dark-blue-50;
}

.colon {
  @include font-body-11;
  color: $color-dark-blue-50;
  margin: 0 5px;
}
