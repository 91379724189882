@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.menu {
  display: none;

  @media (min-width: $md) {
    display: flex;
    flex-direction: column;
  }

  & > * {
    text-decoration: none;
  }
}

.menuIcon {
  cursor: pointer;
}

.navLink {
  border-left: 2px solid transparent;

  &:hover {
    .menuItem {
      color: $color-dark-blue-50;
    }
  }

  &:active {
    .menuItem {
      color: $color-dark-blue-100;
    }
  }
}

.activeNavLink {
  border-left: 2px solid $color-blue-500;

  .menuItem {
    color: $color-blue-500;
  }

  &:hover {
    border-left: 2px solid $color-blue-200;

    .menuItem {
      color: $color-blue-200;
    }
  }

  &:active {
    border-left: 2px solid $color-blue-700;

    .menuItem {
      color: $color-blue-700;
    }
  }
}

.menuItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 42px;
  height: 40px;
  color: $color-dark-blue-400;
}

.menuItemText {
  padding-left: $space-xs;
  @include font-link-2;
}

.externalLinksHeader {
  margin: 40px 0 8px 32px;
  @include font-body-14;
  color: $color-dark-blue-50;
  display: flex;
  align-items: center;
  white-space: nowrap;

  // Horizontal line after the External Links Header
  &::after {
    content: '';
    display: block;
    margin-left: 8px;
    height: 1px;
    background-color: $color-dark-blue-10;
    width: 96px;
  }
}

.externalLink {
  display: flex;
  align-items: center;
  margin-left: 44px;
  height: 40px;
  gap: 8px;
  text-decoration: none;
  white-space: nowrap;
  color: $color-dark-blue-400;
  @include font-link-3;

  img {
    padding: 4px;
  }

  &:hover {
    color: $color-dark-blue-50;
  }

  &:active {
    color: $color-dark-blue-100;
  }
}

.mobileMenuWrapper {
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    display: none;
  }
}

.mobileMenu {
  background-color: $color-dark-blue-700;
  padding: 24px 16px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 350ms;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  @media (min-width: $xs) {
    width: unset;
  }

  &.open {
    transform: translateX(0);
  }

  .activeNavLink {
    border-left: none;
    & * {
      color: $color-green-500;
    }
  }

  .externalLinksHeader {
    color: $color-dark-blue-25;
    margin: 40px 0 24px 0;
    @include font-body-11;

    // Horizontal line after the External Links Header
    &::after {
      content: '';
      display: block;
      margin-left: 7px;
      height: 1px;
      background-color: $color-dark-blue-300;
      width: 131px;
    }
  }

  .externalLink {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 16px;
    height: 24px;
    gap: 16px;
    text-decoration: none;
    @include font-heading-9;

    img {
      width: 12px;
    }
  }
}

.mobileMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}

.mobileMenuScrollWrap {
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  padding: 80px 0 0 0;
  box-sizing: border-box;
}

.mobileMenuContent {
  box-sizing: border-box;
  padding: 0 0 0 16px;

  @media (min-width: $xs) {
    padding: 0 48px;
  }

  & > * {
    color: $color-base-light;
    text-decoration: none;
  }

  svg {
    color: $color-base-light;
    min-width: 24px;
    min-height: 24px;
  }
}

.mobileMenuFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding-top: $space-md;
  box-sizing: border-box;
  max-width: 320px;
  &.borderTop {
    border-top: 1px solid $color-dark-blue-300;
  }
}

.menuMobileItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    min-width: 32px;
    min-height: 32px;
  }
}

.menuMobileItemText {
  @include font-heading-7;
  margin-left: 16px;
  text-decoration: none;
}

.menuIconWrapper {
  display: flex;
  align-items: center;
  height: 56px;

  &.connected {
    height: 64px;
  }
}
