@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.unstakeBanner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #fafafa 0%, #f0f4ff 100%);
  border-top: 1px solid $color-dark-blue-10;
  border-bottom: 1px solid $color-dark-blue-10;
  margin: 32px 0;
  padding: 24px 16px;

  @media (min-width: $sm) {
    margin: 40px 0 72px 0;
    padding: 24px;
    flex-direction: row;
  }
}

.unstakeBannerWrap {
  display: flex;
  align-items: center;

  gap: 16px;
  @include font-body-10;

  & > svg {
    color: $color-action-success-600;
    width: 28px;
    height: 28px;
  }

  @media (min-width: $md) {
    @include font-body-4;
    gap: 24px;

    & > svg {
      width: 36px;
      height: 36px;
    }
  }
}

.buttonPanel {
  display: flex;
  align-items: center;
  gap: 32px;
}
