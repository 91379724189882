@import '../../styles/variables.module.scss';

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &.alignStart {
    align-items: flex-start;
  }

  &.alignCenter {
    align-items: center;
  }

  &.alignEnd {
    align-items: flex-end;
  }
}

.dropdownMenu {
  background-color: $color-blue-10;
  color: $color-dark-blue-50;
  min-width: 100%;
  position: absolute;
  z-index: 20;

  &.top {
    bottom: 100%;
  }

  &.bottom {
    top: 100%;
  }
}

.dropdownIcon {
  & > img {
    width: 24px;
    height: 24px;
    transition: transform 250ms;
    margin-right: 8px;
  }

  &.open {
    & > img {
      transform: rotate(180deg);
    }
  }
}

.dropdownMenuItem {
  padding: 8px 16px;
  border-bottom: 1px solid $color-base-light;

  &.clickable {
    cursor: pointer;
  }
}
