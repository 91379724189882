@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.proposalsList {
  margin-top: 32px;
}

.proposalItem {
  border-top: 1px solid $color-dark-blue-10;
  padding: 34px 0 40px 0;
  max-width: 468px;

  @media (min-width: $sm) {
    padding: 18px 0 22px 0;
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.proposalItemWrapper {
  @media (min-width: $sm) {
    max-width: 392px;
  }

  @media (min-width: $md) {
    max-width: 516px;
  }
}

.proposalItemTitle {
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  a {
    color: $color-dark-blue-400;
    text-decoration: none;
    @include font-description-8;
  }

  @media (min-width: $md) {
    white-space: nowrap;
    display: block;
    padding-right: 16px;
    margin-bottom: 4px;
  }
}

.proposalItemSubtitle {
  display: flex;
  align-items: center;
}

.proposalItemBox {
  display: flex;
  align-items: center;
  @include font-body-14;
  color: $color-dark-blue-100;

  &.mobile {
    margin-bottom: 4px;

    @media (min-width: $md) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media (min-width: $md) {
      display: flex;
      width: 240px;

      & > *:first-child {
        width: 88px;
        text-align: left;
      }
    }
  }
}

.proposalItemTag {
  margin-left: 16px;
  color: $color-dark-blue-400;
}

.proposalVoteBar {
  margin: 32px 40px 40px 40px;
  display: flex;
  align-items: center;

  @media (min-width: $sm) {
    margin: 0;
  }

  @media (min-width: $md) {
    margin-top: 20px;
  }
}

.proposalVoteArrow {
  display: none;

  @media (min-width: $md) {
    display: flex;
    margin: -16px 0 0 28px;
  }
}

.noProposals {
  display: flex;
  flex-direction: column;
  color: $color-dark-blue-400;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  margin: 32px 0;
  padding: 120px 0;
  @include font-body-9;
  border-top: 1px solid $color-dark-blue-10;
  border-bottom: 1px solid $color-dark-blue-10;

  & > * {
    margin-right: $space-xs;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

@media (min-width: $md) {
  .noProposals {
    flex-direction: row;
    align-items: baseline;
    gap: 0;
    padding: 170px 0;
    @include font-body-6;
  }
}
