@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.modalWrapper {
  position: fixed;
  inset: 0;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba($color-base-light, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $sm) {
    padding: 40px;
  }
}

.modal {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: auto;
  box-sizing: border-box;
  background: $gradient-light;

  &Normal {
    padding: 40px 24px;
  }

  &Large {
    padding: 24px;
  }

  @media (min-width: $sm) {
    @include gradient-border($gradient-base-blue-01);
    border-radius: 16px;
    min-height: unset;
    width: auto;

    &Normal {
      padding: 96px 136px;
    }

    &Large {
      padding: 120px 76px;
    }
  }
}

.closeButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > button {
    cursor: pointer;
    margin-left: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    height: 40px;
    color: $color-dark-blue-400;
    transition: all 0.3s;

    &:hover {
      color: $color-dark-blue-100;
    }
  }

  @media (min-width: $sm) {
    position: absolute;
    width: auto;
    top: 32px;
    right: 32px;
  }
}

.modalHeader {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 48px;

  h5 {
    text-wrap: pretty;
    color: $color-dark-blue-400;
  }

  &Normal {
    h5 {
      @include font-heading-9;
    }
  }

  &Large {
    justify-content: space-between;

    h5 {
      @include font-heading-8;
    }

    button {
      display: flex;
      gap: 4px;
      height: auto;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  @media (min-width: $sm) {
    &Normal {
      h5 {
        @include font-heading-6;
      }
    }

    &Large {
      justify-content: center;

      h5 {
        @include font-heading-6;
        margin: 0 auto;
      }

      button {
        gap: 6px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

.modalContent {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 510px;

  @media (min-width: $sm) {
    max-width: unset;

    &Normal {
      width: 510px;
    }

    &Large {
      width: 620px;
    }
  }

  @media (min-width: $md) {
    &Large {
      width: 768px;
    }
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  max-width: 510px;

  @media (min-width: $sm) {
    padding: 0;
  }
}

.noMargin {
  margin: 0;
}
