@mixin button-primary {
  background-color: $color-blue-500;
  color: $color-base-light;
  border: none;

  &.destructive {
    color: $color-base-light;
    background-color: $color-action-error-500;
  }

  &.xxs {
    padding: 0 10px;
    border-radius: 24px;
    height: 24px;
    @include font-body-17;
  }

  &.xs {
    padding: 0 16px;
    border-radius: 24px;
    height: 32px;
    @include font-body-14;
  }

  &.sm {
    padding: 0 20px;
    border-radius: 24px;
    height: 40px;
    @include font-button-3;
  }

  &.md {
    padding: 0 24px;
    border-radius: 28px;
    height: 48px;
    @include font-button-2;
  }

  &.lg {
    padding: 0 32px;
    border-radius: 32px;
    height: 56px;
    @include font-button-1;
  }

  &:hover {
    background-color: $color-blue-200;
  }

  &:active {
    background-color: $color-blue-600;
  }

  &:disabled {
    color: $color-blue-50;
    background-color: $color-blue-10;
  }

  &.destructive {
    &:hover {
      color: $color-base-light;
      background-color: $color-action-error-700;
    }

    &:active {
      color: $color-base-light;
      background-color: $color-action-error-600;
    }

    &:disabled {
      color: $color-action-error-50;
      background-color: $color-action-error-200;
    }
  }

  &.dark {
    background-color: $color-blue-400;

    &:hover {
      background-color: $color-blue-200;
    }

    &:active {
      background-color: $color-blue-500;
    }

    &:disabled {
      color: $color-blue-400;
      background-color: $color-dark-blue-400;
    }
  }
}
