@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.header {
  position: relative;
  pointer-events: none;
  margin-top: 56px;

  @media (min-width: $md) {
    margin-top: 0;
  }
}

.headerTitle {
  @include font-heading-7;
  color: $color-dark-blue-400;

  @media (min-width: $sm) {
    @include font-heading-6;
  }

  @media (min-width: $md) {
    @include font-heading-4;
  }
}
