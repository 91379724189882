@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.stakingPool {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: $space-xxl;
  margin-bottom: 64px;

  @media (min-width: $sm) {
    flex-direction: row;
    align-items: flex-start;
    gap: $space-xxl;
    margin-top: $space-xl;
    margin-bottom: 64px;
    height: 162px;
  }

  @media (min-width: $md) {
    margin-bottom: 72px;
  }
}

.reverseDirectionWrap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: $sm) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.firstSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionRow {
  height: 86px;
  display: flex;
  align-items: center;
  padding: $space-md;
  padding-right: 0;
  box-sizing: border-box;
  gap: $space-xxl;
  width: 100%;
  border-top: 1px solid $color-dark-blue-10;

  &:nth-of-type(2) {
    border-bottom: 1px solid $color-dark-blue-10;
  }

  @media (min-width: $sm) {
    padding-left: 0;

    &:nth-of-type(2) {
      height: unset;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.firstSectionCell {
  display: flex;
  justify-content: space-between;
}

.leftColumn {
  text-align: right;
}

.rightColumn {
  width: 100%;

  & img {
    margin-left: 4px;
    height: fit-content;
    margin: auto 0;
  }
}

.firstSectionLabel {
  @include font-body-6;
  text-wrap: pretty;
  color: $color-dark-blue-100;

  @media (min-width: $sm) {
    @include font-body-9;
  }

  @media (min-width: $md) {
    @include font-body-6;
  }
}

.firstSectionValue {
  @include font-heading-4;
  color: $color-dark-blue-400;
  width: 120px; // Designs are saying 80px here, but they haven't accounted for the decimals

  @media (min-width: $sm) {
    @include font-heading-4;
    width: 104px;
  }

  @media (min-width: $md) {
    @include font-heading-3;
    width: 168px;
  }
}

.firstSectionValueSupplyGrowth {
  @include font-heading-6; // Temporary, until we have the correct font size
  color: $color-dark-blue-400;
  // css below is from the designs, but it doesn't seem to be correct
  // font-family: Inter;
  // font-size: 40.5px;
  // font-style: normal;
  // font-weight: 600;
  // line-height: 60px; /* 148.148% */

  @media (min-width: $sm) {
    @include font-heading-7; // Temporary
  }

  @media (min-width: $md) {
    @include font-heading-6;
  }
}

.secondSection {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 0 $space-xl $space-xs $space-xl;
  border-bottom: 1px solid $color-dark-blue-10;

  @media (min-width: $sm) {
    flex-direction: column;
    padding: 0;
    border: none;
  }
}

.secondSectionRow {
  padding: 0;
  margin: 0;
  width: unset;
  border: none;

  &:nth-of-type(2) {
    border: none;
  }
}

.secondSectionCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  height: 60px;

  @media (min-width: $sm) {
    height: 86px;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 23px 32px 16px 0;
    border-top: 1px solid $color-dark-blue-10;
    border-bottom: 1px solid $color-dark-blue-10;
  }

  @media screen and (min-width: $md) {
    width: 220px;
  }
}

.secondSectionLabel {
  @include font-tagline-11;
  color: $color-dark-blue-100;

  @media (min-width: $sm) {
    @include font-body-13;
  }
}

.secondSectionValue {
  @include font-tagline-2;
  color: $color-dark-blue-400;
  text-align: center;

  @media (min-width: $sm) {
    @include font-body-8;
  }

  @media (min-width: $md) {
    @include font-body-5;
  }
}

.secondSectionCellTarget {
  @media (min-width: $sm) {
    padding-bottom: 0;
    height: unset;
    border: none;
  }
}

.stakingRadialChart {
  display: flex;
  margin-top: $space-xxl;
  margin-bottom: 18px;
  padding: 0 6px 10px 6px;

  @media (min-width: $sm) {
    margin: 0;
  }
}
