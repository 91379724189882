@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.status {
  &.unvoted {
    color: $color-dark-blue-100;
  }

  &.votedAgainst {
    color: $color-action-error-800;
  }

  &.votedFor {
    color: $color-action-success-700;
  }
}

.votedByDelegate {
  @include font-body-17;
}

.wrapperLarge {
  display: flex;
  justify-content: center;
  margin: 14px 0 48px 0;
  @include font-body-11;

  @media (min-width: $md) {
    @include font-body-5;
  }

  .votedByDelegate {
    @include font-body-17;
    text-align: left;

    @media (min-width: $md) {
      @include font-body-14;
    }
  }
}

.icon {
  margin-right: 6px;
  height: 26px;

  @media (min-width: $md) {
    margin-right: 8px;
    height: 30px;
  }
}
