@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.label {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: color 0.2s ease;

  @include font-body-12;
  color: $color-dark-blue-50;
  padding-left: 24px;
  height: 20px;

  &.checked {
    color: $color-dark-blue-200;
  }

  &:hover:not(.checked) {
    color: $color-dark-blue-100;
  }

  @media (min-width: $sm) {
    @include font-body-9;
    padding-left: 32px;
    height: 22px;
  }

  @media (min-width: $md) {
    @include font-body-3;
    height: 30px;
  }
}

.labelLarge {
  @include font-heading-7;
  padding-left: 40px;
  height: auto;

  &.warning {
    color: $color-action-error-600;

    &:hover:not(.checked) {
      color: $color-action-error-400;
    }
  }

  @media (min-width: $sm) {
    @include font-heading-4;
    padding-left: 40px;
  }
}

.input {
  position: absolute;
  left: 0;
  opacity: 0;
}

.icon {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: $sm) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.iconLarge {
  svg {
    width: 27px;
    height: 27px;
  }
}
