@import 'styles/variables.module.scss';

.fontBase {
  font-family: Karla;
}

body {
  @extend .fontBase;
  margin: 0;
  color: $color-dark-blue-400;
}

button,
pre {
  @extend .fontBase;
  font-weight: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html:has(.mobile-menu-open),
html:has(#modal-wrapper) {
  overflow: hidden;
}

// This is third party lib - it is necessary to change z-index
#WEB3_CONNECT_MODAL_ID {
  position: relative;
  z-index: 100;
}

.cursor-auto {
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
