@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.inputWrapper {
  display: inline-block;
  max-width: 100%;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.input {
  display: inline-block;
  max-width: 100%;

  input {
    min-width: 30px;
    background-color: transparent;
    border-width: 0;
    outline: none;
    box-shadow: none;
    text-align: center;
  }

  &.normal {
    input {
      @include font-heading-7;
      color: $color-dark-blue-400;

      &:focus {
        color: $color-dark-blue-400;
      }

      &::placeholder {
        color: $color-dark-blue-400;
      }
    }

    @media (min-width: $sm) {
      min-width: 50px;

      input {
        @include font-heading-4;
      }
    }
  }

  &.small {
    input {
      @include font-body-8;
      color: $color-dark-blue-100;

      &:focus {
        color: $color-dark-blue-100;
      }

      &::placeholder {
        color: $color-dark-blue-100;
      }
    }

    @media (min-width: $sm) {
      input {
        @include font-body-2;
      }
    }
  }
}

// removing input background color/text color for Chrome autocomplete
.input {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #030303 inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
  }
}
