@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';

.accountDropdownOpen {
  background: linear-gradient(180deg, rgba(250, 250, 250, 0.7) 1.5%, rgba(227, 234, 255, 0) 103.92%), #e3eaff;
}

.accountDropdown {
  margin-top: 20px;

  .accountDropdownMenu {
    border-top: none;
    box-shadow: none;
    background-color: inherit;
    border-radius: 0 0 8px 8px;
  }

  .accountDropdownItem {
    display: flex;
    gap: 8px;
    @include font-body-12;
    color: $color-dark-blue-500;
    padding: 16px 32px 16px 40px;
    border: none;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.connectedStatus {
  display: flex;
  align-items: flex-start;
  padding-left: 8px;

  img {
    margin: -20px -20px -20px -20px;
  }
}

.connectButton {
  white-space: nowrap;

  .connectButtonLabel {
    padding: 0 8px;

    @media (min-width: $md) {
      padding: 0 24px;
    }
  }
}

.connectedStatusInfo {
  width: 128px;
  margin: 0 8px;
  margin-bottom: 8px;
}

.accountName {
  color: $color-dark-blue-500;
  @include font-body-6;
}

.connectedTo {
  @include font-body-15;
  color: $color-dark-blue-100;
}

.dark {
  color: $color-base-dark;
  width: unset;

  .accountName {
    color: $color-base-light;
  }

  .connectedTo {
    @include font-body-12;
    color: $color-dark-blue-25;
  }
}

.mobileMenuButton {
  width: 100%;
  color: $color-base-light;
  background-color: $color-base-blue-ui;
  border: none;
  border-radius: 0 0 8px 8px;
  display: flex;
  gap: 16px;
  padding: 24px 40px;
  @include font-body-9;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
}

.mobileMenuConnectedStatus {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 8px;

  & > * {
    margin-bottom: 16px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.hideSignInStatus {
  display: none;
  @media (min-width: $md) {
    display: block;
    height: 96px;
    padding-top: 24px;
  }
}

.unsupportedModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.unsupportedNetworkIcon {
  margin-top: 80px;
  color: $color-action-error-700;

  @media (min-width: $sm) {
    margin-top: 0;
    width: 40px;
    height: 40px;
  }
}

.unsupportedModalContent {
  color: $color-dark-blue-400;
  display: flex;
  flex-direction: column;
  gap: 32px;

  * {
    text-wrap: pretty;
  }

  p {
    @include font-body-9;
  }

  span {
    @include font-body-7;
  }

  @media (min-width: $sm) {
    p {
      @include font-body-6;
    }

    span {
      @include font-body-4;
    }
  }
}

.unsupportedModalButtons {
  padding-top: 48px;
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;

  @media (min-width: $sm) {
    flex-direction: row;
    gap: 0;
  }
}
