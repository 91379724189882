@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';
@import '../../button/variants/link-blue.module.scss';
@import '../layout.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: $color-dark-blue-700;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: $gradient-medium-light;
  }
}

.content {
  padding: 64px 24px 32px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 24px;
  flex: 1;
  flex-direction: column;
  @include font-body-15;
  color: $color-gray-200;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 80px;
    padding: 24px 0;
    max-width: 948px;
    @include font-body-12;
  }
}

.externalLinkIcon {
  margin-left: 4px;
}

.notice {
  max-width: 550px;

  a {
    text-decoration: none;
    @include link-blue;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .manageSettingsButton {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (min-width: $md) {
    width: auto;
    gap: 32px;
  }
}

.closeButton {
  z-index: 1;
  align-self: flex-end;
  margin: 20px 24px;
  position: absolute;
  color: $color-base-light;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: $color-blue-25;
  }

  @media (min-width: $md) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}
