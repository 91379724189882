@import '../../styles/variables.module.scss';

/*
 * NOTE: react-toastify uses static classes so these cannot be placed in CSS modules, as the
 * class names get generated on build.
 *
 * See: https://fkhadra.github.io/react-toastify/how-to-style/
 *
 * Use the classes here to style the react-toastify defaults like backgrounds and
 * containers. Styling of content should be done in the info/success/warning/error
 * components using the notifications.module.scss
 */

.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-container {
  border-radius: 24px;
  padding: 0;
}

.Toastify__toast {
  margin-bottom: 1rem;
}

@media only screen and (min-width: 478px) {
  .Toastify__toast-container {
    width: 480px;
  }
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1rem;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  background-color: $color-dark-blue-700;
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

/** Classes for the progress bar **/
.Toastify__progress-bar,
.Toastify__progress-bar--bg {
  height: 6px;
}

.Toastify__progress-bar--info {
  background-color: $color-action-info-500;
}

.Toastify__progress-bar--success {
  background-color: $color-action-success-500;
}

.Toastify__progress-bar--warning {
  background-color: $color-action-warning-500;
}

.Toastify__progress-bar--error {
  background-color: $color-action-error-500;
}
