@import '../../styles/variables.module.scss';
@import '../../styles/fonts.module.scss';
@import '../button//variants/menu-link-secondary.module.scss';

.layout {
  background: linear-gradient(334deg, #fafafa -0.91%, #ebf0ff 48.81%, #fafafa 99.52%);
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-grow: 1;
}

.main {
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  margin: 0 auto;
  flex: 1;

  @media (min-width: $sm) {
    max-width: 640px;
    padding: 0;
  }

  @media (min-width: $md) {
    max-width: 920px;
    padding: 0 92px;
    box-sizing: content-box;
    padding-bottom: 80px;
  }
}

.footer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 244px;

  &Rows {
    width: calc(100% - 32px);
    max-width: 468px;
  }

  &FirstRow {
    display: flex;
    padding: 44px 0 24px 0;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      @include font-overline-2;
      @include menu-link-secondary;
    }

    > a,
    > button {
      border-right: 1px solid $color-dark-blue-10 !important;
      padding: 0 8px !important;

      &:last-child {
        border-right: none !important;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: $gradient-medium-light;
    }
  }

  &SecondRow {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
    padding: 24px 0 64px 0;
    border-top: 1px solid $color-blue-25;
    width: 100%;

    .privacyLinks {
      display: flex;
      gap: 16px;
    }

    a {
      text-decoration: none;
      box-sizing: border-box;
      @include font-overline-2;
      @include menu-link-secondary;
    }

    .copyright {
      @include font-body-18;
      color: $color-dark-blue-50;
    }
  }
}

@media (min-width: $md) {
  .footer {
    height: 238px;

    &Rows {
      width: calc(100% - 192px);
      max-width: unset;
    }

    a,
    .copyright {
      @include font-body-15;
    }

    &FirstRow {
      padding: 64px 0 32px 0;

      & > button,
      & > a {
        padding: 0 40px !important;
      }
    }

    &SecondRow {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 32px 0 64px 0;

      .privacyLinks {
        gap: 24px;
      }
    }
  }
}
