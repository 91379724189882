@import './variables.module.scss';

body {
  // This is a bit of a hack to stop content scrollbar shifting with Web3modal. It
  // currently (v1.9.3) renders a div at the bottom of the document that is a
  // sibling to the app root div, so it has no context of the current height
  // of the application and if the scrollbar is visible or not. Setting
  // display: contents fixes this.
  display: contents;
}

input {
  margin: 0;
}

:focus {
  box-shadow: $color-base-dark 0 0 2.5px 1.5px;

  // https://css-tricks.com/the-focus-visible-trick/
  &:not(:focus-visible) {
    box-shadow: none;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a {
  border: 0;
  text-decoration: underline;
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.mtXl {
  margin-top: 32px;
}

.mbLg {
  margin-bottom: 24px;
}

.mlXl {
  margin-left: 32px;
}

.helpIcon {
  margin-left: $space-md;
  vertical-align: middle;
}
