@import '../../../styles/variables.module.scss';
@import '../../../styles/fonts.module.scss';

.treasury {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 56px;

  & > * {
    border-bottom: 1px solid $color-dark-blue-10;
  }
}

.treasuryTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  color: $color-dark-blue-100;
  @include font-tagline-9;
}

.treasuryButton {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.label {
  color: $color-dark-blue-100;
  text-transform: capitalize;
  @include font-body-14;
}

.treasuryMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include font-body-10;

  .menuItemLabel {
    color: $color-dark-blue-50;
  }

  .menuItemValue {
    color: $color-dark-blue-100;
  }
}

.etherScanLink {
  margin-left: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
}

.dropdownLink {
  display: flex; // to properly draw box shadow when the link is focused
}

@media (min-width: $md) {
  .treasury {
    max-width: 308px;
    margin-top: 0;
  }

  .treasuryTitle {
    justify-content: end;
    @include font-tagline-6;
  }

  .label {
    @include font-body-11;
  }
}
